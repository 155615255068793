import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import appImage from "../../assets/images/phone1.png";

import "./styles.css";

export const Home = () => {
  const [animate, setAnimate]=useState(false)
  useEffect(()=>{
    setAnimate(true)
  },[]);
  return (
    <section id="home" className="home-content">
      <div className="container">
        <div className={`row align-items-center ${animate ? "slide-in" : ""}`}>
          <div className="col-lg-5 col-md-12 col-12">
            <div
              className={`left-content sliding-container-left ${
                animate ? "slide-in" : ""
              }`}
            >
              <h1 className="title">A powerful app for your business.</h1>
              <p className="description">
                From open source to pro services, Piqes helps you to build,
                deploy, test, and monitor apps.
              </p>
              <div class="btns-container">
                <a className="apple-btn">
                  <FontAwesomeIcon className="icon" icon={faApple} />
                  App Store
                </a>
                <a className="google-btn">
                  <FontAwesomeIcon className="icon" icon={faGooglePlay} />
                  Google Play
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12 ">
            <div
              className={`right-content sliding-container-right ${
                animate ? "slide-in" : ""
              }`}
            >
              <img src={appImage} alt="#" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
