import { useState } from "react";
import logo from "../assets/images/logo-long.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
export const CustomNavbar = () => {
  const [showList, setShowList] = useState(false);

  return (
    <header className="header navbar-container py-3">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="nav-inner">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="index.html">
                  <img src={logo} alt="Logo" />
                </a>
                <button
                  className="navbar-toggler mobile-menu-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <FontAwesomeIcon icon={faBars} />
                </button>
                <div
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <ul id="nav" className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <a
                        href="#home"
                        className="page-scroll"
                        aria-label="Toggle navigation"
                      >
                        Home
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        href="#pricing"
                        className="page-scroll"
                        aria-label="Toggle navigation"
                      >
                        Pricing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#contact"
                        className="page-scroll"
                        aria-label="Toggle navigation"
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="button add-list-button">
                  <a  className="btn">
                    Get it now
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
