// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* variables.css */

:root {
    /* PRIMARY */
    --primary-0-color: #0f0e47;
    --primary-1-color: #2d2c65;
    --primary-2-color: #6968a1;
    --primary-3-color: #8786bf;
  
    /* SECONDARY */
    --secondary-0-color: #ff922d;
    --secondary-1-color: #ffb04b;
    --secondary-2-color: #ffce69;
    --secondary-3-color: #ffec87;
  
    /* ADDITIONAL */
    --additional-0-color: #323232;
    --additional-1-color: #505050;
    --additional-2-color: #6e6e6e;
    --additional-3-color: #8c8c8c;
  
    /* GRAY */
    --gray-0-color: #232323;
    --gray-1-color: #4b4b4b;
    --gray-2-color: #737373;
    --gray-3-color: #9b9b9b;
    --gray-4-color: #c3c3c3;
    --gray-5-color: #ebebeb;
    --gray-6-color: #efefef;
    --gray-7-color: #fafafa;
    --gray-10-color: #fff;
    --gray-100-color: #000;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/variables.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,YAAY;IACZ,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;;IAE1B,cAAc;IACd,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;;IAE5B,eAAe;IACf,6BAA6B;IAC7B,6BAA6B;IAC7B,6BAA6B;IAC7B,6BAA6B;;IAE7B,SAAS;IACT,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,qBAAqB;IACrB,sBAAsB;EACxB","sourcesContent":["/* variables.css */\n\n:root {\n    /* PRIMARY */\n    --primary-0-color: #0f0e47;\n    --primary-1-color: #2d2c65;\n    --primary-2-color: #6968a1;\n    --primary-3-color: #8786bf;\n  \n    /* SECONDARY */\n    --secondary-0-color: #ff922d;\n    --secondary-1-color: #ffb04b;\n    --secondary-2-color: #ffce69;\n    --secondary-3-color: #ffec87;\n  \n    /* ADDITIONAL */\n    --additional-0-color: #323232;\n    --additional-1-color: #505050;\n    --additional-2-color: #6e6e6e;\n    --additional-3-color: #8c8c8c;\n  \n    /* GRAY */\n    --gray-0-color: #232323;\n    --gray-1-color: #4b4b4b;\n    --gray-2-color: #737373;\n    --gray-3-color: #9b9b9b;\n    --gray-4-color: #c3c3c3;\n    --gray-5-color: #ebebeb;\n    --gray-6-color: #efefef;\n    --gray-7-color: #fafafa;\n    --gray-10-color: #fff;\n    --gray-100-color: #000;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
